import { memo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, List, Typography, useMediaQuery } from '@mui/material';

// project imports
import NavItem from './NavItem';
import NavGroup from './NavGroup';
import useConfig from 'hooks/useConfig';
import { useCurrentUser } from 'hooks/useUser.hooks';
import { useCurrentOrganization } from 'hooks/useOrganization.hooks';

import LAYOUT_CONST from 'constant';
import { HORIZONTAL_MAX_ITEM } from 'config';
import { adminMenuItemsWithHH, adminMenuItemsWithoutHH } from 'menu-items/admin';
import deliveryDriverMenuItem from 'menu-items/delivery-driver';
import donorMenuItem from 'menu-items/donor';
import growerMenuItem from 'menu-items/grower';
import recipientMenuItem from 'menu-items/recipient';
import superuserMenuItem from 'menu-items/superuser';
import { useSelector } from 'store';

// types
import { NavItemType } from 'types';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const theme = useTheme();
    const { layout } = useConfig();
    const { drawerOpen } = useSelector((state) => state.menu);
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    
    const userState = useCurrentUser();
    const organizationState = useCurrentOrganization();

	if (!userState.ready || !organizationState.ready) {
        return <div>Loading</div>;
    }
    const user = userState.user;
    const organization = organizationState.organization;

    var menuItem;
    if (user && user.role == 'Admin' && organization.id == 1) { //Leftovers
      menuItem = adminMenuItemsWithHH;
    } else if (user && user.role == 'Admin') {
      menuItem = adminMenuItemsWithoutHH;
    }
    else if (user && user.role == 'Delivery Driver')
    {
      menuItem = deliveryDriverMenuItem;
    }
    else if (user && user.role == 'Food Donor' && user.program.id == 2)
    {
      menuItem = growerMenuItem;
    }
    else if (user && user.role == 'Food Donor')
    {
      menuItem = donorMenuItem;
    }
    else if (user && user.role == 'Recipient')
    {
      menuItem = recipientMenuItem;
    }
    else if (user && user.role == 'Superuser')
    {
      menuItem = superuserMenuItem;
    }

    // last menu-item to show in horizontal menu bar
    const lastItem = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd ? HORIZONTAL_MAX_ITEM : null;

    let lastItemIndex = menuItem.items.length - 1;
    let remItems: NavItemType[] = [];
    let lastItemId: string;

    if (lastItem && lastItem < menuItem.items.length) {
        lastItemId = menuItem.items[lastItem - 1].id!;
        lastItemIndex = lastItem - 1;
        remItems = menuItem.items.slice(lastItem - 1, menuItem.items.length).map((item) => ({
            title: item.title,
            elements: item.children,
            icon: item.icon,
            ...(item.url && {
                url: item.url
            })
        }));
    }

    const navItems = menuItem.items.slice(0, lastItemIndex + 1).map((item) => {
        switch (item.type) {
            case 'group':
                if (item.url && item.id !== lastItemId) {
                    return (
                        <List
                            key={item.id}
                            {...(drawerOpen && layout !== LAYOUT_CONST.HORIZONTAL_LAYOUT && { sx: { mt: 1.5 } })}
                            disablePadding={!drawerOpen || layout === LAYOUT_CONST.HORIZONTAL_LAYOUT}
                        >
                            <NavItem item={item} level={1} isParents />
                            {/* {layout !== LAYOUT_CONST.HORIZONTAL_LAYOUT && <Divider sx={{ py: 0.5 }} />} */}
                        </List>
                    );
                }

                return <NavGroup key={item.id} item={item} lastItem={lastItem!} remItems={remItems} lastItemId={lastItemId} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return layout === LAYOUT_CONST.VERTICAL_LAYOUT || (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd) ? (
        <Box {...(drawerOpen && { sx: { mt: 1.5 } })}>{navItems}</Box>
    ) : (
        <>{navItems}</>
    );
};

export default memo(MenuList);
